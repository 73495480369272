.create-book-modal {
  .form-control-book::placeholder {
    font-size: 14px;
  }

  .author-item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .author-fields {
    display: flex;
    gap: 21px;
  }

  .remove-author-btn {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
}

.book-details-modal {
  .status-check {
    color: blue;
  }

  .status-x {
    color: red;
  }

  .book-history-table-container {
    padding-top: 0.5rem;

    .book-history-table th {
      font-size: 14px;
    }

    .table-column-date {
      width: 100px;
    }

    .center-align {
      align-content: center;
    }
  }
}

.book-list-modal {
  .select-all-checkbox {
    position: relative;
    display: inline;
    left: 8px;
    bottom: 1px;
  }

  .no-books-found {
    text-align: center;
  }
}

.manage-books-tab-container {
  position: relative;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;

  .books-list-actions-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    z-index: 1;

    & > * {
      flex: 1;
    }

    .books-list-actions-left {
      min-width: 300px;
    }

    .books-list-actions-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5rem;
      min-width: 220px;
    }
  }

  .book-custom-card {
    background: #f2f2f299 !important;
    box-sizing: border-box;
    transition:
      box-shadow 0.3s ease,
      transform 0.3s ease;
  }

  .book-custom-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transform: translateY(2px);
  }

  .book-custom-card .card-title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }

  .book-custom-card .card-subtitle {
    font-size: 1rem !important;
    color: #6c757d !important;
  }

  .book-custom-card .card-text {
    text-align: center;
    margin-bottom: 6px !important;
  }

  .book-card-body {
    padding: 14px !important;
  }

  .book-card-text {
    font-weight: bolder;
    font-size: 16px !important;
    margin-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-group {
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
    gap: 0.5rem;
  }

  .three-dots {
    border-radius: 16px;
    border: aliceblue;
    position: absolute !important;
    background: #f2f2f299;
    top: 14px;
    right: 10px;
  }

  .book-created-by {
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .book-publisher,
  .book-created-date {
    margin-top: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .book-create-date {
    margin-bottom: 6px;
  }

  .book-card-discipline {
    font-weight: bolder;
    font-size: 16px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .book-published-icon {
    color: #42d142;
    position: absolute;
    right: 30px;
    top: 15px;
  }

  .book-title-text {
    text-align: center;
  }

  .three-dots-icon {
    color: grey;
  }

  .three-dots :hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }

  .no-matching-books {
    position: absolute;
    text-align: center;
    height: 100%;
    top: 0;
    left: 0;
  }

  .books-list-grid {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    row-gap: 1em;
    padding-bottom: 1rem;
    overflow-y: auto;
  }

  .book-card-buttons {
    padding: 4px 14px !important;
    border-radius: 4px !important;
  }

  .book-card-body hr {
    margin: 6px !important;
  }
}
