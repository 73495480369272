.manage-questions-container {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 10px;
  background-color: #e8e8e8;

  .left-section {
    background-color: #fff;
    flex: 1;
    padding-inline: 0.5em;
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(50% - 5px);

    .books-title-header {
      display: flex;
      gap: 0.5em;
      min-height: 47px;
      align-items: center;

      .btn-back-to-books {
        display: flex;
        min-height: 23px;
        min-width: 24px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        text-decoration: none;
        border-radius: 50%;

        &:focus {
          box-shadow: 0 0 0 0.1rem #000;
        }

        .back-arrow-icon {
          font-size: 21px;
          color: #007fa9;
        }
      }

      .selected-book-title {
        display: flex;
        gap: 0.25em;
        min-width: 0;
      }
    }

    .folder-actions-container {
      display: flex;
      gap: 1em;
      align-items: center;
      justify-content: flex-start;
      padding: 3px;
      background-color: #ddd;
    }
  }

  .right-section {
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-inline: 0.5em;
    position: relative;
    width: calc(50% - 5px);

    .questions-save-action-contianer {
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 3px;
      background-color: #ddd;
    }

    .add-question-button {
      min-width: 15%;
      cursor: pointer;
      padding: 5px;
    }

    .questions-list-container {
      justify-content: space-between;
      display: flex;
      gap: 0.3em;
      min-height: 47px;
      align-items: center;

      button {
        font-size: 0.8em;
      }
    }
  }

  .manage-questions-instructions-container {
    position: absolute;
    background-color: #f5f5f5;
    width: calc(100% - 1em);
    border-radius: 0px;
    top: 12rem;
    padding: 2.5rem;

    .flex-box {
      display: flex;
      align-items: start;
      gap: 1.25em;
    }
  }

  .folders-tree-view {
    ul {
      list-style: none;
      padding-left: 13px;
    }

    & > ul {
      box-sizing: border-box;
      list-style: none;
      padding: 5px 0px 0px;
      margin-bottom: 0px;
    }

    .ws-folders-tree-node {
      display: flex;
      align-items: start;
      padding: 10px 72px 10px 25px;
      padding-right: 72px;
      background-color: #f8f8f8;
      font-size: 14px;
      color: #4d4d4d;
      position: relative;
      /* line-height: 1em; */
      margin-top: 2px;

      .caret-container {
        cursor: pointer;
        position: relative;
        top: 3px;
      }

      .tree-node-text {
        padding-left: 8px;
        position: relative;
        top: 3px;
      }

      &.is-question {
        padding-right: 35px;
      }

      &.clicked {
        background-color: #007fa9;
        color: white;

        .action-button {
          background-color: #007fa9 !important;
        }
      }

      .tree-node-action-buttons-container {
        position: absolute;
        right: 2px;
        top: 2px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        background-color: transparent;
        width: 68px;

        &.is-question {
          width: 31px;
        }

        .action-button {
          border-radius: 3px;
          background-color: #f8f8f8;
          border: none;
          padding: 8px;

          &:hover {
            border-color: #007fa9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }

          &.selected {
            border-color: #007fa9;
            background-color: #007fa9;
          }
        }
      }
    }
  }

  .openbook {
    padding-left: 5px;
  }
}

.create-questions {
  margin-left: 12px;
}

.edit-saved-question-modal {
  .modal-body {
    overflow: auto;
    padding-block: 0.5em;

    & > div {
      background-color: transparent !important;
      border: 0 !important;
      margin: 0 !important;
      padding-inline: 0.5rem !important;
    }

    .editmode {
      max-height: 100% !important;
    }
  }
}
